import { render, staticRenderFns } from "./goBackRouter.vue?vue&type=template&id=9692b826&scoped=true&"
import script from "./goBackRouter.vue?vue&type=script&lang=js&"
export * from "./goBackRouter.vue?vue&type=script&lang=js&"
import style0 from "./goBackRouter.vue?vue&type=style&index=0&id=9692b826&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9692b826",
  null
  
)

export default component.exports