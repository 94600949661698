<template>
    <div class="main">
        <!-- <div class="viewRouter">
            <goBACK :routerName="'LoginView'"></goBACK>
            <h3>欢迎来到大芈智能无感肌群训练系统！</h3>
        </div> -->
        <el-row :gutter="20" id="nav">
            <!-- <el-col :span="4"><div class="grid-content bg-purple" id="nav-back">
                <goBACK :routerName="'LoginView'"></goBACK>
            </div></el-col> -->
            <div id="nav-title">
                <h3>欢迎来到大芈智能无感肌群训练系统！</h3>
            </div>
            <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
        </el-row>
        <div class="mid">
          <div class="tableMain">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm" label-width="100px">
              <h4>注册超管账号</h4>
              <el-form-item  prop="account" label="账号">
                <el-input  v-model="ruleForm.account" autocomplete="off" placeholder="请输入账号"  prefix-icon="el-icon-user-solid"></el-input>
              </el-form-item>
              <el-form-item  prop="password" label="设置密码">
                <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"  prefix-icon="el-icon-lock"></el-input>
              </el-form-item>
              <el-form-item  prop="password" label="确认密码">
                <el-input type="password" v-model="ruleForm.rePassword" autocomplete="off" placeholder="请输入密码"  prefix-icon="el-icon-lock"></el-input>
              </el-form-item>
              <el-button  @click="submitForm('ruleForm')">提交</el-button>
            </el-form>
          </div>
        </div>
</div>
</template>
  
  <script>
  import goBACK from "@/components/goBackRouter"
  import { AXIOSaddUser} from '@/api/user'
  
  export default {
    components:{
      goBACK,
    },
    data(){
      var validateAccount = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入账号'));
          return 
        } 
        callback();
      };
      var validatePassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
          return 
        } 
        if (value.length < 6 || value.length > 16) {
          callback(new Error('密码长度要求为6-16位'));
          return 
        }
        callback();
      };
      return {
        ruleForm:{account:"",password:"",rePassword:"",type:"0"},
        rules:{account:[{validator: validateAccount, trigger: 'blur'}],password:[ {validator: validatePassword, trigger: 'blur'}],rePassword:[ {validator: validatePassword, trigger: 'blur'}]},
      }
    },
    created(){
    
    },
    methods:{
      submitForm(formName){
        if (this.ruleForm.password != this.ruleForm.rePassword) {
            this.$message("两次密码不一致")
            return
        }
        this.$refs[formName].validate(
          async (valid)=>{
            if(valid){
              try {
                const {data} = await AXIOSaddUser(
                  {
                    account:this.ruleForm.account,
                    password:this.$md5(this.ruleForm.password),
                    type:this.ruleForm.type,
                  }
                )
                 if(data.code === 200){
                  this.$message.success(data.msg)
                  this.ruleForm = { account:"",password:"", }
                  alert("注册成功")
                  return 
                }
                this.$message(data.msg)

              } catch (error) {
                this.$message("操作失败! 请重试")
              }
            }else{
              console.log("submit error")
              return false
            }
          }
        ) 
      },
    }
  }
  </script>
  
  <style lang="less" scoped> 
  .mid{
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h4{
    font-size: 24px;
    margin-bottom: 4%;
  }
  #nav{
    height: 100px;
    line-height: 100px;
  }

  #nav-back{
    margin: 0;
    padding-left: 20px;
  }

  #nav-title{
    font-size: 34px;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  /deep/.el-form-item__error{
    font-size: 18px;
    padding-top: 8px;
    padding-left: 4px;
  }
  /deep/ .el-form{
    // margin-top: 2%;
    width: 60%;
    }
    /deep/ .el-form-item{
      margin-bottom: 7%;
    }
    
    /deep/.el-form-item__label{
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    /deep/ .el-input__inner{
      background: #EDEFFF;
      border-radius: 12px;
      border: 2px solid #4C65FF;
    }
    /deep/ .el-form-item__content{
      text-align: center;
    }
    /deep/ .el-radio__label{
      font-size: 20px;
    }
    /deep/.mid .el-form .el-button{
      margin: 2% 0;
      background: #4C65FF;
      color: #FFFFFF;
      width: 120px;
      height: 40px;
      padding: 0;
      font-size: 20px;
      border-radius: 30px;
    }
  
  // 表格
  .tableMain{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    min-width: 830px;
    border: 5px solid #DBDEFF;
    border-radius: 20px;
    padding: 2% 3%;
    margin:2% 0 1% 0 ;
    /deep/ .el-button{
      background: #4C65FF;
      color: #FFFFFF;
      font-size: 16px;
      height: 35px;
      padding: 0 20px;
      border-radius: 20px;
    }
  }
  .minH{
    min-height: 342px;
  }
  /deep/ .cell{
    text-align: center;
  }
  .viewRouter{
  background: #FFFFFF;
  width: 100%;
  height: 44px;
  margin: 2%;
  font-size: 34px;
}
  /deep/ .el-table__header .el-table__cell{
    color: #333333;
    font-size: 24px;
    font-weight: bold;
  }
  /deep/ .el-table__body .el-table__cell{
    color: #333333;
    font-size: 18px;
  }
  /deep/ .el-table{
    margin-bottom: 3%;
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    // p{
    //   font-size: 20px;
    //   color: #333333;
    //   margin-right: 1%;
    // }
  }
  </style>