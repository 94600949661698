<template>
  <div class="main">
       <div class="goBack">
        <button @click="goFiles(routerName,queryName,souName)"><i class="el-icon-arrow-left"></i><span>返回</span></button>
      </div>
  </div>
</template>

<script>
export default {
  props:["routerName","queryName","souName"],
  data(){
    return {
      
    }
  },
  created(){
  },  
  methods:{
    goFiles(Name,qName,sName){
      // this.$router.push({name:Name , query:{routerName:qName , souName:sName } })
      window.history.back()
    },
  }
  
}
</script>

<style lang="less" scoped>
.goBack{
  text-align: left;
  button{
     background-color: rgba(1, 1, 1, 0);
     i{
       vertical-align: -10%;
       font-size: 30px;
       font-weight: bold;
       color:#4C65FF;
     }
     span{
       font-size: 26px;
     }
  }
}
</style>