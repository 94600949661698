import Vue from 'vue'
import VueRouter from 'vue-router'
import RegisterView from '../views/RegisterView.vue'
import store from '@/store' // 引入vuex store实例 用于权限判断
import NProgress from "nprogress"
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'RegisterView',
    component: RegisterView,
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  // 大屏可视化
  {
    // path: '/controlView/:IDNO',
    path: '/controlView',
    name: 'controlView',
    // rediect:"/controlView/:IDNO", this.$route.params.IDNO
    component: () => import('../views/controlView.vue')
  },
  //注册
  {
    path: '/RegisterView',
    name: 'RegisterView',
    component: () => import('../views/RegisterView.vue')
  },
  //忘记密码
  {
    path: '/ForgetView',
    name: 'ForgetView',
    component: () => import('../views/ForgetView.vue')
  },
  {
    path: '/selectView',
    name: 'selectView',
    component: () => import('../views/selectView.vue'),
    children:[
      // 公共page 
        // 信息操作
        {
          path: 'publicHandleView',
          name: 'publicHandleView',
          component: () => import('../views/publicPage/publicHandleView.vue')
        },
        // 个人指标
        {
          path: 'publicEnterView',
          name: 'publicEnterView',
          component: () => import('../views/publicPage/publicEnterView.vue')
        },
        // 治疗方案
        {
          path: 'publicPlanView',
          name: 'publicPlanView',
          component: () => import('../views/publicPage/publicPlanView.vue')
        },
      // 治疗者档案
      {
        path: 'filesView',
        name: 'filesView',
        component: () => import('../views/filesSum/filesView.vue')
      },
      {
        path: 'pushView',
        name: 'pushView',
        component: () => import('../views/filesSum/pushView.vue')
      },
      {
        path: 'seeView',
        name: 'seeView',
        component: () => import('../views/filesSum/seeView.vue')
      },
        {
          path: 'seeBaseView',
          name: 'seeBaseView',
          component: () => import('../views/filesSum/seeBaseView.vue')
        },
        {
          path: 'seeRecordView',
          name: 'seeRecordView',
          component: () => import('../views/filesSum/seeRecordView.vue')
        },
        {
          path: 'recordEnterView',
          name: 'recordEnterView',
          component: () => import('../views/filesSum/recordEnterView.vue')
        },
      // 无感训练
      {
        path: 'trainView',
        name: 'trainView',
        component: () => import('../views/trainSum/trainView.vue')
      },
      //串口通信
      {
        path: 'serialPortView',
        name: 'serialPortView',
        component: () => import('../views/trainSum/serialPortView.vue')
      },
        
      // 系统设置
      {
        path: 'setUpView',
        name: 'setUpView',
        component: () => import('../views/setSum/setUpView.vue')
      },
        {
          path: 'passwordView',
          name: 'passwordView',
          component: () => import('../views/setSum/passwordView.vue')
        },
        {
          path: 'commandView',
          name: 'commandView',
          component: () => import('../views/setSum/commandView.vue')
        },
        {
          path: 'accountView',
          name: 'accountView',
          component: () => import('../views/setSum/accountView.vue')
        },
        {
          path: 'updataView',
          name: 'updataView',
          component: () => import('../views/setSum/updataView.vue')
        },
    ]
  },

  
  
]

const router = new VueRouter({
  routes
})

// 白名单（未登录也可访问的路由地址）
// const whiteList = ['/login', '/404'] // 定义白名单  所有不受权限控制的页面
// 路由的前置守卫
const routerViewList = [
  "/HomeView","/RegisterView","/ForgetView",'/selectView',
  // 公共↓
  '/selectView/publicHandleView','/selectView/publicEnterView','/selectView/publicPlanView',
  // 治疗者档案
  '/selectView/filesView',"/selectView/pushView", "/selectView/seeView","/selectView/seeBaseView",
  "/selectView/seeRecordView","/selectView/recordEnterView",
  // 无感肌群训练
  '/selectView/trainView',
  // 串口通信
  '/selectView/serialPortView',
  // 系统设置
  '/selectView/setUpView','/selectView/passwordView','/selectView/commandView','/selectView/accountView','/selectView/updataView'
]
router.beforeEach(function(to, from, next) {
  NProgress.start() // 开启进度条
  if(to.path === '/'){
    if(store.getters.token){
      next('HomeView') 
    }else{
      next()
    }
    NProgress.done()
    return
  }
  //  || "/controlView/:IDNO"
  if(to.path === '/controlView' || '/controlView/:IDNO'){
    next()
    NProgress.done()
    return
  }
  // 判断是否为管理员
  if(to.path === '/selectView/accountView' || to.path === '/selectView/updataView' ){
    if(store.getters.vuexAccountInfo.type === 1 ){
      next()
    }else{
      next('HomeView') 
    }
    NProgress.done()
    return
  }
  if (routerViewList.indexOf(to.path) > -1) {
  // 如果找到了 表示在名单里面 
    if(store.getters.token){
      next() 
    }else{
      next('/')
    }
    NProgress.done()
    return
  }
   // NProgress.done() 手动强制关闭一次  为了解决 手动切换地址时  进度条的不关闭的问题

//   if(to.path === '/infostartperson/infopersoninfor'){
//     if(store.getters.logintoken && store.getters.personInfostate === 1 ){
//       // 登录且 state === 1 放行
//       next()
//     }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
